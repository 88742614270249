<template>
  <div class="contactblock">
    <div class="contactblock__wrapper">
      <div class="contactblock__content">
        <div class="gallery__item"><AppHl>Kontakt</AppHl></div>
        <div class="block__text block__text--2">
          <a :href="getMail" class=""> {{ kontakt.email }}</a
          ><br />
          <a :href="getPhone" class="">{{ kontakt.phone }}</a>
        </div>
        <br />
        <div class="block__text">
          Genießen Sie mit uns zusammen den wunderschönen Handwerker Markt in Schwabing.
          <br>
          Wir freuen uns auf Sie.
        </div>
        <div class="block__p">
        Wir legen Wert auf echte Handarbeit und Qualität der Produkte.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import contactInformation from "@/utilities/siteinformation/contactInformation";
import AppHl from "@/components/ui/buttons/AppHl.vue";

export default {
  components: {  AppHl},
  setup() {
    const kontakt = contactInformation;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    return { kontakt, getMail, getPhone };
  },
};
</script>

<style lang="scss">
.contactblock__wrapper {
  position: relative;
  width: 100%;
}
</style>
