<template>
  <div class="gallery">
    <div class="gallery__wrapper">
      <div class="gallery__item"><AppHl>Veranstaltung</AppHl></div>
      <div class="gallery__content">
             <div class="gallery__hero">
            <GalleryPic :imgpath="'assets/img/gallery/halle/halle.jpg'" />
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHl from "@/components/ui/buttons/AppHl.vue";

import GalleryPic from "./GalleryPic";
export default {
  components: { GalleryPic, AppHl },
  setup() {},
};
</script>

<style></style>
