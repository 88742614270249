<template>
  <div class="lovebrands">
    <div class="lovebrands__wrapper">
      <div class="lovebrands__content">
        <div class="block__text">
          <div class="block__text--small">
            15. Weihnachtlicher Kunsthandwerker
          </div>
          <div class="block__text--big">
            MARKT der MÖGLICHKEITEN
          </div>
        </div>
        <div class="block__text">
          Kunst zum Anfassen,<br />Handwerk hautnah erleben, <br />originelle
          Unikate direkt vom Hersteller erwerben!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LoveBrandsAnimationStart from "./LoveBrandsAnimationStart";
export default {
  // components: { LoveBrandsAnimationStart },
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.lovebrands__hl {
  color: white;
  text-align: center;
  margin: 0 auto;
}

.block__text--small {
  font-size: 70%;
}
.block__text--big {
  font-family: inherit;
  font-size: 180%;
}
</style>
