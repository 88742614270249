<template>
  <div class="page page__home">
    <HeaderPic :imgpath="'assets/img/header/0.jpg'" />
    <LoveBrandsBlock class="section" />
    <GalleryProjekts class="section" />
    <ContactBlock class="section" />
    <GalleryOffice class="section" />
    <TheFooter class="section" />
  </div>
</template>

<script>
import HeaderPic from "@/components/common/header/HeaderPic";
import LoveBrandsBlock from "@/components/common/loveBrands/LoveBrandsBlock.vue";
import GalleryProjekts from "@/components/common/gallery/GalleryProjekts.vue";
import ContactBlock from "@/components/common/contact/ContactBlock.vue";
import GalleryOffice from "@/components/common/gallery/GalleryOffice.vue";
import TheFooter from "../components/common/footer/TheFooter.vue";

import { useStore } from "vuex";

export default {
  components: {
    HeaderPic,
    LoveBrandsBlock,
    ContactBlock,
    GalleryProjekts,
    GalleryOffice,
    TheFooter,
  },

  setup() {
    const store = useStore();
    // MENU STYLINGS:

    return { store };
  },
};
</script>
<style
  lang="
    scss"
></style>
