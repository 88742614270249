<template>
  <div class="apphl" ref="apphl">
    <div class="apphl__text">
      <slot></slot>
      <div class="apphl__marker"></div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "@vue/runtime-core";
export default {
  props: { link: String },
  setup(props) {
    const appbutton = ref(null);

    const randomNumb = ref(null);
    onMounted(() => {
      if (props.link) {
        appbutton.value.addEventListener("click", openNewTab);
      }

      function openNewTab(e) {
        e.stopPropagation();
        e.preventDefault();
        randomNumb.value = "?" + Math.floor(Math.random() * 10000000000000000);
        window.open(props.link + randomNumb.value, "_blank");
      }
    });
    return { appbutton, randomNumb };
  },
};
</script>

<style lang="scss">
.apphl {
  border-bottom: 1px solid $main-color-1;
  display: inline-block;
  margin-bottom: 20px;

  @media screen and (min-width: $medium) {
    border-bottom: 3px solid $main-color-1;
    display: inline-block;
    margin-bottom: 40px;
  }
  @media screen and (min-width: $large) {
    border-bottom: 3px solid $main-color-1;
    display: inline-block;
    margin-bottom: 60px;
  }
  @media screen and (min-width: $xlarge) {
    border-bottom: 3px solid $main-color-1;
    display: inline-block;
    margin-bottom: 80px;
  }
}
.apphl__text {
  font-family: $font-family-herohl;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;

  @media screen and (min-width: $small) {
    font-size: 28px;
    line-height: 1.2;
    opacity: 1;
  }

  @media screen and (min-width: $medium) {
    font-size: 43px;
    line-height: 1.2;
    opacity: 1;
  }
}
</style>
