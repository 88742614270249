<template>
  <div class="gallery">
    <div class="gallery__wrapper">
      <div class="gallery__item">
        <AppHl>Aussteller</AppHl>
      </div>
      <div class="gallery__content">
        <div class="gallery__leftblock">
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_1.jpg'" />
          </div>
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_1.jpg'" />
          </div>
        </div>
        <div class="gallery__rightblock">
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_2.jpg'" />
          </div>
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_2.jpg'" />
          </div>
        </div>
      </div>
      <div class="gallery__content">
        <div class="gallery__leftblock">
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_3.jpg'" />
          </div>
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_3.jpg'" />
          </div>
        </div>
        <div class="gallery__rightblock">
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_4.jpg'" />
          </div>
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_4.jpg'" />
          </div>
        </div>
      </div>
      <div class="gallery__content">
        <div class="gallery__leftblock">
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_5.jpg'" />
          </div>
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_5.jpg'" />
          </div>
        </div>
        <div class="gallery__rightblock">
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_6.jpg'" />
          </div>
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_6.jpg'" />
          </div>
        </div>
      </div>
      <div class="gallery__content">
        <div class="gallery__leftblock">
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_7.jpg'" />
          </div>
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_7.jpg'" />
          </div>
        </div>
        <div class="gallery__rightblock">
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_8.jpg'" />
          </div>
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_8.jpg'" />
          </div>
        </div>
      </div>

      <div class="gallery__content">
        <div class="gallery__leftblock">
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_9.jpg'" />
          </div>
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_9.jpg'" />
          </div>
        </div>
        <div class="gallery__rightblock">
          <div class="gallery__small">
            <GalleryPic :imgpath="'assets/img/gallery/overview/q_10.jpg'" />
          </div>
          <div class="gallery__big">
            <GalleryPic :imgpath="'assets/img/gallery/overview/h_10.jpg'" />
          </div>
        </div>
      </div>



      <!-- <div class="gallery__btn">
        <AppButton :routerlink="'projekte'">Weitere Projekte</AppButton>
      </div> -->
    </div>
  </div>
</template>

<script>
// import AppButton from "@/components/ui/buttons/AppButton.vue";
import AppHl from "@/components/ui/buttons/AppHl.vue";
import GalleryPic from "./GalleryPic";
export default {
  components: { GalleryPic, AppHl },
  setup() { },
};
</script>

<style></style>
