<template>
  <div class="header" ref="header">
    <div class="header__wrapper" ref="headerWrapper">
      <div
        class="header__img"
        :style="[
          { backgroundImage: setBackGroundPath(imgpath) },
          { backgroundPosition: imgposition },
        ]"
      ></div>
      <div class="header__block">
        <div class="header__logo">  <the-logo /></div>
      
      </div>
    </div>
  </div>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import TheLogo from "@/components/ui/logo/TheLogo.vue";
import { ref } from "@vue/runtime-core";
export default {
  components:{TheLogo},
  props: { imgpath: String, imgposition: String, motion: String },
  setup() {
    const { setBackGroundPath } = getPathFunction();

    const headerWrapper = ref(null);
    const header = ref(null);

    return { setBackGroundPath, headerWrapper, header };
  },
};
</script>

<style lang="scss">
.header {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-bottom: 60px;

  @media screen and (min-width: $small) {
    margin-bottom: 80px;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 107px;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 160px;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 160px;
  }
}
.header--aspectratio {
  position: relative;
  width: 100%;
  margin-bottom: 50px;

  @media screen and (min-width: $small) {
    margin-bottom: 75px;
  }
  @media screen and (min-width: $medium) {
    margin-bottom: 100px;
  }
  @media screen and (min-width: $large) {
    margin-bottom: 130px;
  }
  @media screen and (min-width: $xlarge) {
    margin-bottom: 130px;
  }
}

.header__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  // padding-bottom: 56.25%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header__block{
  width: 100%;
  text-align: center;
  position: relative;
  box-sizing: border-box;
}
.header__img {
  position: absolute;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 1;
}

.header__hl {
  position: relative;
  text-transform: uppercase;
  color: white;
  font-size: 40px;
  text-align: center;
  font-family: $font-family-herohl;

  @media screen and (min-width: $small) {
    font-size: 80px;
  }
  @media screen and (min-width: $medium) {
    font-size: 80px;
  }
  @media screen and (min-width: $large) {
    font-size: 80px;
  }
  @media screen and (min-width: $xlarge) {
    font-size: 80px;
  }
}
.header__sl {
  z-index: 100;
  position: relative;
  text-transform: uppercase;
  color: $main-color-1;
  font-size: 30px;
  text-align: center;
  font-family: $font-family-second;

  @media screen and (min-width: $small) {
    font-size: 30px;
  }
  @media screen and (min-width: $medium) {
    font-size: 30px;
  }
  @media screen and (min-width: $large) {
    font-size: 30px;
  }
  @media screen and (min-width: $xlarge) {
    font-size: 40px;
  }
}

.header__logo{
width:30%;
min-width: 300px;
max-width: 500px;
margin: 0 auto;
}
</style>
